import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  settings: () => "/settings",
  paymentMethods: () => "/payment-methods",
  subscriptions: () => "/subscriptions",
  subscription: (subscriptionId: string) => `/subscriptions/${subscriptionId}`,
};

export class BuyerRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return (
      super.isRestrictedRoute(pathname) ||
      [
        RouteMap.settings(),
        RouteMap.paymentMethods(),
        RouteMap.subscriptions(),
        RouteMap.subscription("[id]"),
      ].includes(pathname || this.pathname)
    );
  }

  /*
   * Restricted Routes - goTo
   */

  goToSettings() {
    this.goTo(RouteMap.settings());
  }

  goToPaymentMethods() {
    this.goTo(RouteMap.paymentMethods());
  }

  goToSubscriptions() {
    this.goTo(RouteMap.subscriptions());
  }

  goToSubscription(subscriptionId: string) {
    this.goTo(RouteMap.subscription(subscriptionId));
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteSettings() {
    return this.pathname === RouteMap.settings();
  }
}
