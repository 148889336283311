globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"96bkk9pC-AEntx6r3COPr"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { init } from "@pl/cfg-sentry/sentry.client.config";

init({
  dsn: "https://befff06b6aabba06b0f244dd41b94c0c@o496444.ingest.us.sentry.io/4507624928313344",
  tunnel:
    "https://hooli.paymentlabs.io?tethics_key=befff06b6aabba06b0f244dd41b94c0c&project_id=4507624928313344",
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enableReplayIntegration: true,
  enableCaptureConsoleIntegration: true,
  enableHttpClientIntegration: true,
});
