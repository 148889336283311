import { ReactNode } from "react";
import { useDevUtils } from "@pl/app-services";
import { MainLoadingGate } from "#app-common/components/MainLoadingGate";

export type DevUtilsLoadingGateProps = {
  children: ReactNode;
  basePath?: string;
  embeddable?: boolean;
};

export function DevUtilsLoadingGate({
  children,
  basePath,
  embeddable,
}: DevUtilsLoadingGateProps) {
  const { isLoading } = useDevUtils({
    basePath,
    embeddable,
  });

  if (isLoading) {
    return <MainLoadingGate />;
  }

  return children;
}
