import { createContext, useState, useEffect } from "react";
import { IntlProvider as ReactIntlProvider, IntlShape } from "react-intl";
import { useRouter } from "next/router";
import { shouldPolyfill } from "@formatjs/intl-displaynames/should-polyfill";
import * as Sentry from "@sentry/nextjs";
import { logError, logWarn, useTimeout } from "@pl/app-services";
import { noop } from "#app-common/utils/noop";

// Adapted from
// https://formatjs.io/docs/polyfills/intl-displaynames#dynamic-import--capability-detection
async function polyfill(locale: string) {
  if (!shouldPolyfill(locale)) {
    return;
  }

  await import("@formatjs/intl-displaynames/polyfill-force");

  // The reason we're not using
  // `import("@formatjs/intl-displaynames/locale-data/${locale}")` is because
  // that generates a chunk for every possible locale, and there are many of
  // them. So many, in fact, that the manifest grows so large it becomes its own
  // chunk of over 17 kB. `_app`'s size also increases by 30+ kB.
  //
  // For the large number of chunks, this makes sense since webpack will
  // generate every possible chunk given the template string. Not ideal to
  // enumerate them manually but it's a solution.
  //
  // For the `_app` bundle size increase, no idea what's happening. We can
  // reduce it to about a third of the size by enumerating the dynamic imports
  // this way, so that kind of solves this problem too. It doesn't make sense
  // since they're dynamic imports and shouldn't show up in the bundle.. but at
  // least it's not an unreasonable increase doing it this way.
  //
  // Both behaviors have been described here
  // https://github.com/formatjs/formatjs/issues/4339 . Should there be a
  // solution proposed there, we should update the below accordingly.
  if (locale === "en") {
    await import("@formatjs/intl-displaynames/locale-data/en");
  } else if (locale === "es") {
    await import("@formatjs/intl-displaynames/locale-data/es");
  } else if (locale === "fr") {
    await import("@formatjs/intl-displaynames/locale-data/fr");
  } else if (locale === "hi") {
    await import("@formatjs/intl-displaynames/locale-data/hi");
  } else if (locale === "it") {
    await import("@formatjs/intl-displaynames/locale-data/it");
  } else if (locale === "ja") {
    await import("@formatjs/intl-displaynames/locale-data/ja");
  } else if (locale === "ko") {
    await import("@formatjs/intl-displaynames/locale-data/ko");
  } else if (locale === "pl") {
    await import("@formatjs/intl-displaynames/locale-data/pl");
  } else if (locale === "pt") {
    await import("@formatjs/intl-displaynames/locale-data/pt");
  } else if (locale === "ru") {
    await import("@formatjs/intl-displaynames/locale-data/ru");
  } else if (locale === "vi") {
    await import("@formatjs/intl-displaynames/locale-data/vi");
  } else {
    logError({
      message: `Unsupported locale ${locale} encountered in IntlProvider polyfill for Intl.DisplayNames`,
    });
  }
}

const DEFAULT_LOCALE = "en";

export type IntlContextValue = {
  hasCatalogLoaded: boolean;
};

export const IntlContext = createContext<IntlContextValue>(
  {} as IntlContextValue
);

export type IntlProviderProps = {
  children: JSX.Element;
  defaultMessageCatalog: IntlShape["messages"];
  fetchTargetLocaleMessageCatalog: (locale: string) => Promise<{
    default: IntlShape["messages"];
  }>;
};

export function IntlProvider({
  children,
  defaultMessageCatalog,
  fetchTargetLocaleMessageCatalog,
}: IntlProviderProps): JSX.Element {
  const { locale, route } = useRouter();
  const [targetLocaleMessageCatalog, setTargetLocaleMessageCatalog] = useState<
    IntlShape["messages"] | undefined
  >();
  const [hasCatalogLoaded, setHasCatalogLoaded] = useState(
    locale === DEFAULT_LOCALE
  );

  // Will issue a fetch for the target catalog only once per locale + route.
  // If it fails, emit catalog fetch success to short-circuit the default
  // messages fallback, try again on the next route.
  useEffect(() => {
    Sentry.setTag("locale", locale);
    polyfill(locale || DEFAULT_LOCALE);

    if (locale && locale !== DEFAULT_LOCALE && !targetLocaleMessageCatalog) {
      fetchTargetLocaleMessageCatalog(locale)
        .then((mod) => mod.default)
        .then((catalog) => {
          setTargetLocaleMessageCatalog(catalog);
          setHasCatalogLoaded(true);
        })
        .catch(noop)
        .finally(() => setHasCatalogLoaded(true));
    }
  }, [
    locale,
    route,
    targetLocaleMessageCatalog,
    fetchTargetLocaleMessageCatalog,
  ]);

  // In case the target locale catalog fetch hangs past 2s, emit catalog fetch
  // success to short-circuit the default messages fallback.
  useTimeout(() => {
    setHasCatalogLoaded(true);
  }, 2000);

  const value: IntlContextValue = {
    hasCatalogLoaded,
  };

  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProvider
        locale={locale || DEFAULT_LOCALE}
        messages={targetLocaleMessageCatalog || defaultMessageCatalog}
        onError={(err) =>
          logError({ message: "react-intl error", exception: err })
        }
        onWarn={(err) => logWarn({ message: `react-intl warning: ${err}` })}
        // Ensure translations are always wrapped in a span to mitigate Google Translate
        // "Failed to execute 'removeChild' on 'Node'" error.
        // https://github.com/facebook/react/issues/11538
        textComponent={"span"}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
}
