import { IconProps } from "../types";
import { getIconClassNames, getIconA11yAttrs, getIconTitle } from "../utils";

/*
 * Material Icon: Error
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Aerror%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function WarningCircleIcon(props: IconProps) {
  return (
    <svg
      className={getIconClassNames(props)}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="M479.789-288Q495-288 505.5-298.289q10.5-10.29 10.5-25.5Q516-339 505.711-349.5q-10.29-10.5-25.5-10.5Q465-360 454.5-349.711q-10.5 10.29-10.5 25.5Q444-309 454.289-298.5q10.29 10.5 25.5 10.5Zm0-144Q495-432 505.5-442.35 516-452.7 516-468v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-672 454.5-661.65 444-651.3 444-636v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Zm.487 336Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
    </svg>
  );
}
