import React from "react";
import { Tooltip as ReactTooltip, WrapperType } from "react-tooltip";
import cx from "classnames";

let globallyUniqueTtId = 0;

export type TooltipProps = {
  message: React.ReactNode;
  tooltipId: string;
  children: React.ReactNode;
  reactTootlipWrapper?: WrapperType;
};

export function Tooltip({
  message,
  tooltipId,
  children,
  reactTootlipWrapper = "span",
}: TooltipProps) {
  return (
    <span data-testid={`tooltip-${tooltipId}`}>
      <span className={cx("flex", tooltipId)}>{children}</span>
      <ReactTooltip
        id={tooltipId}
        className={cx(
          "text-txt-inverse break-normal text-left",
          "absolute z-10 max-w-60 p-2 sm:max-w-xs",
          "!text-body-md !bg-int-bg-primary !rounded-md !shadow !shadow-md"
        )}
        anchorSelect={`.${tooltipId}`}
        wrapper={reactTootlipWrapper}
      >
        {message}
      </ReactTooltip>
    </span>
  );
}

export function generateTtId() {
  return ++globallyUniqueTtId;
}

export type TooltipWithoutWrapperProps = {
  children: React.ReactNode;
  tooltipId: string;
  reactTootlipWrapper?: WrapperType;
};

export function TooltipWithoutWrapper({
  children,
  tooltipId,
  reactTootlipWrapper = "div",
}: TooltipWithoutWrapperProps) {
  return (
    <ReactTooltip
      id={tooltipId}
      className={cx(
        "text-txt-inverse break-normal text-left",
        "absolute z-10 max-w-60 p-2 sm:max-w-xs",
        "!text-body-md !bg-int-bg-primary !rounded-md !shadow !shadow-md"
      )}
      wrapper={reactTootlipWrapper}
    >
      {children}
    </ReactTooltip>
  );
}
