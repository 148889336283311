import { QueryClient } from "@tanstack/react-query";
import cx from "classnames";
import { ThemeConfig } from "@pl/app-services";
import { ErrorBoundary } from "#app-common/components/ErrorBoundary";
import { useRegisterSessionRedirects } from "#app-common/hooks/useRegisterSessionRedirects";
import { AppContainer } from "#app-common/modules/app-shell/AppContainer";
import {
  AppProvider,
  AppProviderProps,
} from "#app-common/providers/AppProvider";

export type AppRootProps = {
  children: React.ReactNode;

  /*
   * Topmost wrapping div
   */

  fontClassName?: string;

  /*
   * AppContainer
   */

  fullHeight?: boolean;

  /*
   * ThemeProvider
   */

  themeConfig?: ThemeConfig;

  /*
   * QueryClientProvider
   */

  queryClient?: QueryClient;

  /*
   * IntlProvider
   */

  defaultMessageCatalog: AppProviderProps["defaultMessageCatalog"];
  fetchTargetLocaleMessageCatalog: AppProviderProps["fetchTargetLocaleMessageCatalog"];

  /*
   * ErrorBoundary
   */

  resetApp: () => void;
};

export function AppRoot({
  children,
  fullHeight,
  themeConfig,
  fontClassName,
  queryClient,
  defaultMessageCatalog,
  fetchTargetLocaleMessageCatalog,
  resetApp,
}: AppRootProps) {
  useRegisterSessionRedirects();

  return (
    <div data-app-root className={cx(fontClassName)}>
      <ErrorBoundary
        topMostRootBoundary
        boundaryContext="topmost root error boundary"
        resetApp={resetApp}
      >
        <AppProvider
          themeConfig={themeConfig}
          queryClient={queryClient}
          defaultMessageCatalog={defaultMessageCatalog}
          fetchTargetLocaleMessageCatalog={fetchTargetLocaleMessageCatalog}
        >
          <AppContainer fullHeight={fullHeight}>
            <ErrorBoundary
              boundaryContext="app-wrapping error boundary"
              resetApp={resetApp}
            >
              {children}
            </ErrorBoundary>
          </AppContainer>
        </AppProvider>
      </ErrorBoundary>
    </div>
  );
}
