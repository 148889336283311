import { isClientSide } from "#app-services/utils/browser";
import { logInfo } from "./logger";

export const PL_AUTH_TOKEN_SESSION_STORAGE_KEY = "pl_auth";

export function getAuthTokenFromSessionStorage() {
  if (!isClientSide) {
    return null;
  }

  let token = null;
  try {
    token = sessionStorage.getItem(PL_AUTH_TOKEN_SESSION_STORAGE_KEY);
  } catch (e) {
    // Logging for info purposes to tie issues to incognito mode or other edge
    // cases. Not critical because there's nothing to be done - sessionStorage
    // may or may not exist, nothing we can do whether the failure is expected
    // or not.
    logInfo({
      message: "Failed to get token from sessionStorage",
      data: e as object,
    });
    return null;
  }

  return token;
}

export function setAuthTokenInSessionStorage(token: string) {
  if (!isClientSide) {
    return false;
  }

  try {
    sessionStorage.setItem(PL_AUTH_TOKEN_SESSION_STORAGE_KEY, token);
  } catch (e) {
    // See above comment
    logInfo({
      message: "Failed to set auth token in sessionStorage",
      data: e as object,
    });
    return false;
  }

  return true;
}
