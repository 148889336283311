import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  checkoutAttempt: (checkoutId?: string) => `/checkout/attempt/${checkoutId}`,
  subscription: (subscriptionId?: string) =>
    `/checkout/subscription/${subscriptionId}`,
  buyerSubscription: (subscriptionId?: string) =>
    `/buyer/subscriptions/${subscriptionId}`,
  invalidLink: () => "/invalid-link",
};

export class CheckoutRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return super.isRestrictedRoute(pathname);
  }

  goToSignUp(encryptedConfig?: string): void {
    this.goTo(`${super.getRouteMap().signUp()}?config=${encryptedConfig}`);
  }

  goToInvalidLink(): void {
    this.goTo(this.getRouteMap().invalidLink());
  }

  goToForgotPassword(encryptedConfig?: string): void {
    this.goTo(
      `${super.getRouteMap().forgotPassword()}?config=${encryptedConfig}`
    );
  }

  /**
   * [0] - type
   * [1] - id (checkoutId or subscriptionId)
   *
   * @param args
   */
  goToLogin(...args: string[]): void {
    let redirectUrl = "";

    const type = args[0];
    const id = args[1];

    if (type === "checkout") {
      redirectUrl = this.getRouteMap().checkoutAttempt(id);
    } else if (type === "subscription") {
      redirectUrl = this.getRouteMap().subscription(id);
    }

    this.goTo(`${super.getRouteMap().login()}?_redirectUrl=${redirectUrl}`);
  }

  goToResetPassword(encryptedConfig?: string): void {
    this.goTo(
      `${super.getRouteMap().resetPassword()}?config=${encryptedConfig}`
    );
  }

  goToSubscription(id: string): void {
    this.goTo(this.getRouteMap().buyerSubscription(id), "external");
  }

  isCurrentRouteSubscription(redirectUrl: string): boolean {
    if (redirectUrl) {
      return redirectUrl.includes("subscription");
    }

    return this.pathname.includes("subscription");
  }

  isCurrentRouteCheckout(redirectUrl: string): boolean {
    if (redirectUrl) {
      return redirectUrl.includes("attempt");
    }

    return this.pathname.includes("attempt");
  }
}
