import { ComponentType } from "react";
import cx from "classnames";
import { IconProps } from "#app-component-lib/icons/types";

export type IconFrameProps = {
  Icon: ComponentType<IconProps>;
  color?: "default" | "info" | "positive" | "negative" | "warning";
};

export function IconFrame({ color, Icon }: IconFrameProps) {
  return (
    <div
      className={cx(
        "flex size-11 shrink-0 items-center justify-center rounded-full",
        {
          "bg-bg-main": color === "default" || !color,
          "bg-bg-info-light": color === "info",
          "bg-bg-positive-light": color === "positive",
          "bg-bg-negative-light": color === "negative",
          "bg-bg-warning-light": color === "warning",
        }
      )}
    >
      <Icon color={color} size="md" />
    </div>
  );
}
