import { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import staticConfig from "@pl/cfg-tailwind/static-config";

export type Breakpoint = "2xs" | "xs" | "sm" | "md" | "lg" | "xl";

const breakpointsByNumericValue = Object.entries(
  staticConfig.BreakpointMap
).reduce(
  (acc, screen) => {
    const [bp, pxValue] = screen;
    acc[bp as Breakpoint] = Number((pxValue as string).replace("px", ""));
    return acc;
  },
  {} as Record<Breakpoint, number>
);
const { xs, sm, md, lg, xl } = breakpointsByNumericValue;

function getBpMapForWidth(width: number) {
  const is3Xs = width > 0 && width < breakpointsByNumericValue["2xs"];
  const is2Xs = width >= breakpointsByNumericValue["2xs"] && width < xs;
  const isXs = width >= xs && width < sm;
  const isSm = width >= sm && width < md;
  const isMd = width >= md && width < lg;
  const isLg = width >= lg && width < xl;
  const isXl = width >= xl;

  return {
    is3Xs,
    is2Xs,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isMobile: is3Xs || is2Xs || isXs,
    isTablet: isSm || isMd,
    isDesktop: isLg || isXl,
    // Avoid false positives with negation (ie `!isMobile`) in case
    // no/inaccurate value on initial render
    isResponsiveReady: width > 0,
  };
}

export function useResponsive(windowLike?: Window) {
  const initialWidth = windowLike ? windowLike.innerWidth : 0;
  const [state, setState] = useState(getBpMapForWidth(initialWidth));
  const currState = useRef(state);

  useEffect(() => {
    if (!windowLike) {
      return;
    }

    const resizeHandler = debounce(() => {
      const newBpMap = getBpMapForWidth(windowLike.innerWidth);
      if (JSON.stringify(newBpMap) === JSON.stringify(currState.current)) {
        return;
      }
      currState.current = newBpMap;
      setState(newBpMap);
    }, 100);

    windowLike.addEventListener("resize", resizeHandler);
    return () => windowLike.removeEventListener("resize", resizeHandler);
  }, [windowLike]);

  return state;
}

/*
 * Generated by ChatGPT
 */
export function hexToHsl(hex: string): [number, number, number] | null {
  // Check if the input is a valid hex color code
  const hexRegex = /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
  if (!hexRegex.test(hex)) {
    console.error("Invalid hex color code");
    return null;
  }

  // Remove the hash if present
  hex = hex.replace(/^#/, "");

  // Parse the hex values for R, G, B
  const bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);

    switch (max) {
      case r:
        h = (g - b) / (max - min) + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / (max - min) + 2;
        break;
      case b:
        h = (r - g) / (max - min) + 4;
        break;
    }

    h /= 6;
  }

  // Return HSL values in the range [0, 1]
  return [h, s, l];
}

/*
 * Generated by ChatGPT
 *
 * Handles both the following cases:
 * - "hsl(220 12% calc(60% - 50%))"
 * - "hsl(228 63% 56%)"
 */
export function hslToHex(hslString: string) {
  // Regular expression to match both formats of HSL
  const hslRegex =
    /hsl\(\s*(\d+)\s+(\d+%)\s+(?:calc\((\d+)%\s*-\s*(\d+)%\)|(\d+%))\s*\)/;
  const match = hslString.match(hslRegex);

  if (!match) {
    return null;
  }

  const hue = parseInt(match[1]); // Extract hue value
  const saturation = parseInt(match[2]); // Extract saturation value

  let lightness;
  if (match[3] !== undefined && match[4] !== undefined) {
    lightness = parseInt(match[3]) - parseInt(match[4]); // Calculate lightness after subtraction
  } else if (match[5] !== undefined) {
    lightness = parseInt(match[5]); // Extract lightness value directly
  } else {
    return null;
  }

  // Convert percentage values to 0-1 range
  const s = saturation / 100;
  const l = lightness / 100;

  // Calculate RGB values
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
  const m = l - c / 2;

  let r, g, b;
  if (hue >= 0 && hue < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (hue >= 60 && hue < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (hue >= 120 && hue < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (hue >= 180 && hue < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (hue >= 240 && hue < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  // Convert RGB to Hex
  r = Math.round((r + m) * 255)
    .toString(16)
    .padStart(2, "0");
  g = Math.round((g + m) * 255)
    .toString(16)
    .padStart(2, "0");
  b = Math.round((b + m) * 255)
    .toString(16)
    .padStart(2, "0");

  return `#${r}${g}${b}`.toUpperCase();
}
