import * as Sentry from "@sentry/nextjs";

export type LogOpts = {
  message?: string;
  data?: object;
};

export function logDebug({ message, data }: LogOpts) {
  if (process.env.NODE_ENV === "production") {
    if (data) {
      Sentry.addBreadcrumb({
        category: "log_data",
        level: "debug",
        data,
      });
    }
    if (message) {
      Sentry.captureMessage(message, { level: "debug" });
    }
  } else {
    console.debug(message, data);
  }
}

export function logInfo({ message, data }: LogOpts) {
  if (process.env.NODE_ENV === "production") {
    if (data) {
      Sentry.addBreadcrumb({
        category: "log_data",
        level: "info",
        data,
      });
    }
    if (message) {
      Sentry.captureMessage(message, { level: "info" });
    }
  } else {
    console.info(message, data);
  }
}

export function logWarn({ message, data }: LogOpts) {
  if (process.env.NODE_ENV === "production") {
    if (data) {
      Sentry.addBreadcrumb({
        category: "log_data",
        level: "warning",
        data,
      });
    }
    if (message) {
      Sentry.captureMessage(message, { level: "warning" });
    }
  } else {
    console.warn(message, data);
  }
}

export type LogErrorOpts = LogOpts & {
  exception?: Error;
};

export function logError({ message, exception, data }: LogErrorOpts) {
  if (process.env.NODE_ENV === "production") {
    if (data) {
      Sentry.addBreadcrumb({
        category: "log_data",
        level: "error",
        data,
      });
    }
    if (message) {
      Sentry.captureMessage(message, { level: "error" });
    }
    if (exception) {
      Sentry.captureException(exception, { level: "error" });
    }
  } else {
    console.error(message, data);
  }
}

export function logFatal({ message, exception, data }: LogErrorOpts) {
  if (process.env.NODE_ENV === "production") {
    if (data) {
      Sentry.addBreadcrumb({
        category: "log_data",
        level: "fatal",
        data,
      });
    }
    if (message) {
      Sentry.captureMessage(message, { level: "fatal" });
    }
    if (exception) {
      Sentry.captureException(exception, { level: "fatal" });
    }
  } else {
    console.error(message, data);
  }
}
