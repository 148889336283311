import cx from "classnames";

export type AppContainerProps = {
  children: React.ReactNode;
  fullHeight?: boolean;
};

export function AppContainer({ children, fullHeight }: AppContainerProps) {
  return (
    <div
      className={cx("flex", {
        "min-h-screen": fullHeight,
      })}
    >
      {children}
    </div>
  );
}
