import React from "react";
import { logError } from "@pl/app-services";
import {
  FullPageError,
  FullPageErrorNoI18n,
} from "#app-common/components/FullPageNotice";
import { AppContainer } from "#app-common/modules/app-shell/AppContainer";
import { Main } from "#app-common/modules/app-shell/Main";

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  topMostRootBoundary?: boolean;
  boundaryContext?: string;
  resetApp: () => void;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logError({
      message: "ErrorBoundary componentDidCatch",
      exception: error,
      data: {
        errorInfo,
        errorBoundaryContext: this.props.boundaryContext,
      },
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.topMostRootBoundary ? (
        <AppContainer>
          <Main>
            <FullPageErrorNoI18n cta={this.props.resetApp} />
          </Main>
        </AppContainer>
      ) : (
        <Main>
          <FullPageError cta={this.props.resetApp} />
        </Main>
      );
    }

    return <>{this.props.children}</>;
  }
}
