import { useEffect } from "react";
import { hexToHsl } from "@pl/app-component-lib";
import { logError, ColorSeedToken, ThemeConfig } from "@pl/app-services";

export type ThemeProviderProps = {
  children: React.ReactNode;
  themeConfig?: ThemeConfig;
};

export function ThemeProvider({ children, themeConfig }: ThemeProviderProps) {
  useEffect(() => {
    if (!themeConfig?.colors) {
      return;
    }

    const { seed, overrides } = themeConfig.colors;

    if (!seed && !overrides) {
      return;
    }

    const themeRoot = document.querySelector("[data-app-root]") as HTMLElement;

    if (!themeRoot) {
      logError({
        message: `Encountered existence of urlQuery but [data-app-root] querySelector did not return an element, this shouldn't happen`,
      });
      return;
    }

    if (seed) {
      Object.entries(seed).forEach(([token, hexCode]) => {
        if (!hexCode) {
          return;
        }

        updateSeedCssVariablesForToken(
          themeRoot,
          token as ColorSeedToken,
          hexCode
        );
      });
    }

    if (overrides) {
      Object.entries(overrides).forEach(([token, hexCode]) => {
        if (!hexCode) {
          return;
        }

        themeRoot.style.setProperty(`--color-${token}`, hexCode);
      });
    }
  }, [themeConfig]);

  return children;
}

function updateSeedCssVariablesForToken(
  themeRoot: HTMLElement,
  token: ColorSeedToken,
  hexCode: string
) {
  const hsl = hexToHsl(hexCode);
  if (!hsl) {
    return;
  }

  const baseToken = `--color-${token}-hsl`;
  const [h, s, l] = hsl;
  themeRoot.style.setProperty(`${baseToken}-h`, `${Math.round(h * 360)}`);
  themeRoot.style.setProperty(`${baseToken}-s`, `${Math.round(s * 100)}%`);
  themeRoot.style.setProperty(`${baseToken}-l`, `${Math.round(l * 100)}%`);
}
