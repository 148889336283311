import type { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import Script from "next/script";
import * as Sentry from "@sentry/nextjs";
import {
  AppRoot,
  DevUtilsLoadingGate,
  I18nLoadingGate,
  Main,
} from "@pl/app-common";
// eslint-disable-next-line no-restricted-imports
import "@pl/app-common/src/global.css";
// eslint-disable-next-line no-restricted-imports
import "@pl/app-component-lib/src/global.css";
import { useEnvStore } from "@pl/app-services";
import "@pl/cfg-tailwind/global.css";
import "@pl/cfg-tailwind/theme.css";
import defaultMessageCatalog from "../.lang/build/en.json";

const font = Inter({
  subsets: ["latin"],
});

// Needs to be passed as a function, because this relative path only works in
// this location. Easy solution without having to resort to SSG'ing with
// absolute paths.
function fetchTargetLocaleMessageCatalog(locale: string) {
  return import(`../.lang/build/${locale}.json`);
}

Sentry.setTag("app", "multi-zone-gateway");
useEnvStore.getState().update({
  app: "gateway",
  apiBaseurl: process.env.NEXT_PUBLIC_API_BASEURL,
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Payment Labs</title>
        <meta
          name="title"
          content="Payment Labs - Make Payments Effortlessly"
        />
        <meta
          name="description"
          content="How Payments Should Be — Fast, Global, And Always, Low Rates"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.paymentlabs.io" />
        <meta
          property="og:title"
          content="Payment Labs - Make Payments Effortlessly"
        />
        <meta
          property="og:description"
          content="How Payments Should Be — Fast, Global, And Always, Low Rates"
        />
        <meta
          property="og:image"
          content="https://cdn.paymentlabs.io/branding/opengraph_secondary.png"
        />

        <meta name="twitter:site" content="@paymentlabs_io" />
        <meta name="twitter:creator" content="@paymentlabs_io" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.paymentlabs.io" />
        <meta
          property="twitter:title"
          content="Payment Labs - Make Payments Effortlessly"
        />
        <meta
          property="twitter:description"
          content="How Payments Should Be — Fast, Global, And Always, Low Rates"
        />
        <meta
          property="twitter:image"
          content="https://cdn.paymentlabs.io/branding/opengraph_secondary.png"
        />

        <link
          rel="shortcut icon"
          href="https://cdn.paymentlabs.io/favicon_secondary.ico"
          type="image/x-icon"
        />
      </Head>
      <noscript>
        <iframe
          title="Google Tag Manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-5WVSVCGB"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5WVSVCGB');
          `}
      </Script>
      <AppRoot
        fullHeight
        fontClassName={font.className}
        defaultMessageCatalog={defaultMessageCatalog}
        fetchTargetLocaleMessageCatalog={fetchTargetLocaleMessageCatalog}
        resetApp={() => window.location.reload()}
      >
        <DevUtilsLoadingGate>
          <I18nLoadingGate>
            <Main backgroundClassName="login-background">
              {/* https://stackoverflow.com/a/71869550 */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Component {...pageProps} />
            </Main>
          </I18nLoadingGate>
        </DevUtilsLoadingGate>
      </AppRoot>
    </>
  );
}
