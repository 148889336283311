import { RenderStateSection } from "@pl/app-component-lib";
import { Main } from "#app-common/modules/app-shell/Main";

export function MainLoadingGate() {
  return (
    <Main className="items-center">
      <RenderStateSection variant="loading" height="xl" />
    </Main>
  );
}
