import { logInfo } from "./logger";

export function setLocalStorageItem(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    logInfo({
      message: `Failed to set local storage key: ${key} and value: ${value}`,
      data: e as object,
    });
  }
}

export function getLocalStorageItem(key: string) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    logInfo({
      message: `Failed to retrieve local storage key: ${key}`,
      data: e as object,
    });
    return "";
  }
}

export function removeLocalStorageItem(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    logInfo({
      message: `Failed to remove local storage key: ${key}`,
      data: e as object,
    });
  }
}
