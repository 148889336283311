import { IconProps } from "../types";
import { getIconA11yAttrs, getIconClassNames, getIconTitle } from "../utils";

/*
 * Material Icon: Check Circle
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Acheck_circle%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function CheckCircleIcon(props: IconProps) {
  return (
    <svg
      className={getIconClassNames(props)}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="m429-438-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641-599q-11-11-25-11t-25 11L429-438Zm51 342q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
    </svg>
  );
}
