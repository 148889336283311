import { QueryClient } from "@tanstack/react-query";
import { ThemeConfig } from "@pl/app-services";
import {
  IntlProvider,
  IntlProviderProps,
} from "#app-common/providers/IntlProvider";
import { QueryClientProvider } from "#app-common/providers/QueryClientProvider";
import { ThemeProvider } from "#app-common/providers/ThemeProvider";

export type AppProviderProps = {
  children: React.ReactNode;
  themeConfig?: ThemeConfig;
  queryClient?: QueryClient;
  defaultMessageCatalog: IntlProviderProps["defaultMessageCatalog"];
  fetchTargetLocaleMessageCatalog: IntlProviderProps["fetchTargetLocaleMessageCatalog"];
};

export function AppProvider({
  children,
  themeConfig,
  queryClient,
  defaultMessageCatalog,
  fetchTargetLocaleMessageCatalog,
}: AppProviderProps) {
  return (
    <IntlProvider
      defaultMessageCatalog={defaultMessageCatalog}
      fetchTargetLocaleMessageCatalog={fetchTargetLocaleMessageCatalog}
    >
      <QueryClientProvider queryClient={queryClient}>
        <ThemeProvider themeConfig={themeConfig}>{children}</ThemeProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}
