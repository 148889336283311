/*
 * Adapted from https://github.com/luixaviles/event-bus-typescript
 */

export type Registry = {
  unregister: () => void;
};

export type Callable = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (...args: any[]) => void;
};

export type Subscriber = {
  [key: string]: Callable;
};

export interface IEventBus {
  dispatch<T, K>(event: T, arg?: K): void;
  register<T, K>(event: T, callback: (args: K) => void): Registry;
}

export class EventBus implements IEventBus {
  private subscribers: Subscriber;
  private static nextId = 0;
  private static instance?: EventBus = undefined;

  private constructor() {
    this.subscribers = {};
  }

  public static getInstance(): EventBus {
    if (this.instance === undefined) {
      this.instance = new EventBus();
    }

    return this.instance;
  }

  public dispatch<T = never, K = never>(event: T, arg?: K): void {
    const subscriber = this.subscribers[event as string];

    if (subscriber === undefined) {
      return;
    }

    Object.keys(subscriber).forEach((key) => subscriber[key](arg));
  }

  public register<T = never, K = never>(
    event: T,
    callback: (args: K) => void
  ): Registry {
    const id = this.getNextId();
    const eventAsString = event as string;
    if (!this.subscribers[eventAsString]) this.subscribers[eventAsString] = {};

    this.subscribers[eventAsString][id] = callback;

    return {
      unregister: () => {
        delete this.subscribers[eventAsString][id];
        if (Object.keys(this.subscribers[eventAsString]).length === 0)
          delete this.subscribers[eventAsString];
      },
    };
  }

  private getNextId(): number {
    return EventBus.nextId++;
  }
}
