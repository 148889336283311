import cx from "classnames";
import { IconProps } from "../types";
import { getIconClassNames, getIconTitle, getIconA11yAttrs } from "../utils";

/*
 * Material Icon: Progress Activity
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Aprogress_activity%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function LoadingSpinnerIcon(props: IconProps) {
  return (
    <svg
      data-testid="loading-spinner-icon"
      className={cx(getIconClassNames(props), "animate-spin")}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="M480-96q-78.72 0-148.8-30.24-70.08-30.24-122.4-82.56-52.32-52.32-82.56-122.4Q96-401.28 96-480q0-79.68 30.216-149.28T208.71-751.2q52.278-52.32 122.302-82.56Q401.036-864 479.693-864 496-864 506-854t10 25.509q0 15.509-10 26T480-792q-129.675 0-220.837 90.5Q168-611 168-480.5T259.163-259Q350.325-168 480-168q131 0 221.5-91.163Q792-350.325 792-480q0-16 10.491-26t26-10Q844-516 854-506t10 26.307q0 78.657-30.24 148.681-30.24 70.024-82.56 122.302-52.32 52.278-121.92 82.494T480-96Z" />
    </svg>
  );
}
