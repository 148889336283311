import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  batch: (batchId: string) => `/batches/${batchId}`,
  allBatches: () => "/batches/all",
  allPayments: () => "/payments/all",
  settings: () => "/settings",
};

export class PayorRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return (
      super.isRestrictedRoute(pathname) ||
      [
        RouteMap.batch("[id]"),
        RouteMap.allBatches(),
        RouteMap.allPayments(),
        RouteMap.settings(),
      ].includes(pathname || this.pathname)
    );
  }

  /*
   * Restricted Routes - goTo
   */

  goToBatch(id: string) {
    this.goTo(RouteMap.batch(id));
  }

  goToAllBatches() {
    this.goTo(RouteMap.allBatches());
  }

  goToAllPayments() {
    this.goTo(RouteMap.allPayments());
  }

  goToSettings() {
    this.goTo(RouteMap.settings());
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteSettings() {
    return this.pathname === RouteMap.settings();
  }
}
