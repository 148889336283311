import { useEffect, useRef } from "react";
import {
  EventBus,
  SESSION_EXPIRED_EVENT_DATA,
  SESSION_EXPIRED_EVENT_ID,
} from "@pl/app-services";
import { usePaymentLabsRouter } from "#app-common/hooks/usePaymentLabsRouter/usePaymentLabsRouter";

/*
 * I ran into something weird here - if this hook is moved to app-services, it
 * leads to test breakages (anything testing a route change using next/router or
 * window.open). The app appears to function just fine, it's just a test thing.
 * After digging into it I narrowed it to the `useRouter` call. If it's never
 * instantiated, the tests still work fine. And then I found something even
 * weirder - if the next/router mock is moved from being global in the jest
 * `testSetupFile` to local inside a specific test, we see the error `NextRouter
 * was not mounted`. Somehow, when mocked directly inside a jest spec (while
 * `useRouter()` is called in app-services), the mock
 * doesn't apply.
 *
 * So the issue is: calling `useRouter()` inside of `app-services` causes
 * routing assertions in tests to fail because something breaks with the mock.
 * Tried copying over configs like jest and next, nothing. Makes no sense. But
 * not worth digging further because we don't have the same issue in app-common,
 * where this hook arguably is better suited.
 */

export function useRegisterSessionRedirects() {
  // It's possible more than one 401 will occur in succession, which will lead
  // to overlapping redirects.
  const fourOhOneRedirected = useRef(false);
  const router = usePaymentLabsRouter();

  useEffect(() => {
    const { unregister } = EventBus.getInstance().register<
      SESSION_EXPIRED_EVENT_ID,
      SESSION_EXPIRED_EVENT_DATA
    >("401", () => {
      if (!fourOhOneRedirected.current) {
        fourOhOneRedirected.current = true;
        router.goToSessionExpired();
      }
    });

    return () => unregister();
  }, [router]);
}
