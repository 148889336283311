import cx from "classnames";
import { IconProps } from "./types";

export function getIconClassNames(props: IconProps) {
  return cx(getIconSize(props), getIconFill(props));
}

export function getIconSize({ size }: IconProps) {
  return cx({
    "size-5": size === "sm" || !size,
    "size-6": size === "md",
    "size-8": size === "lg",
  });
}

export function getIconFill({ color, noFill }: IconProps) {
  // If an icon is an interactive link/button, and the link/button applies a
  // different text color on hover/active, assigning the fill at the SVG level
  // would lead to the SVG not changing along with the text on hover/active - it
  // instead needs to be set on the link/button. But not in all interactive
  // contexts, it really depends (like a tertiary button which changes color on
  // hover, but a tooltip icon that doesn't - where both are interactive). In
  // these cases, noFill is provided as an escape hatch.
  if (noFill) {
    return;
  }

  return cx({
    "fill-icon": color === "default" || !color,
    "fill-icon-inverse": color === "inverse",
    "fill-icon-brand": color === "brand",
    "fill-icon-info": color === "info",
    "fill-icon-positive": color === "positive",
    "fill-icon-negative": color === "negative",
    "fill-icon-warning": color === "warning",
  });
}

export function getIconA11yAttrs({
  interactive,
  decorative,
  trigger,
  linkOrButton,
}: IconProps) {
  if (!interactive) {
    return {};
  }

  return {
    "aria-hidden": decorative || linkOrButton ? true : undefined,
    role: trigger ? "img" : undefined,
  };
}

export function getIconTitle({ interactive, trigger, title }: IconProps) {
  if (interactive && trigger) {
    return <title>{title}</title>;
  }

  return null;
}
