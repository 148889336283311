import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {};

export class GatewayRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  goTo(url: string) {
    // Unrestricted account routes on the gateway are always internal.
    super.goTo(
      url,
      this.isUnrestrictedAccountRoute(url) ? "internal" : undefined
    );
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return super.isRestrictedRoute(pathname);
  }
}
