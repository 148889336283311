/*
 * The only supported way of getting TW config values in JS is using their
 * `resolveConfig` approach
 * https://tailwindcss.com/docs/configuration#referencing-in-java-script This is
 * problematic because it increases bundle size substantially, by around 20kb
 * gz. The team is aware of this:
 *
 * ```
 * Note that this will transitively pull in a lot of our build-time
 * dependencies, resulting in bigger client-side bundle size. To avoid this, we
 * recommend using a tool like babel-plugin-preval to generate a static version
 * of your configuration at build-time.
 * ```
 *
 * Going the `babel-plugin-preval` could be a good approach in the future if we
 * need it, but it's just unnecessary now. All we need (for now) are
 * breakpoints, which are otherwise static.
 *
 * This is a good pattern for all static values that need to be referenced at
 * runtime - just move them here and reference only this file directly.
 */

const BreakpointMap = {
  "2xs": "320px",
  xs: "480px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1440px",
};

module.exports = { BreakpointMap };
