import cx from "classnames";
import { IconFrame } from "#app-component-lib/data-display/IconFrame/IconFrame";
import { LoadingSpinner } from "#app-component-lib/feedback/LoadingSpinner/LoadingSpinner";
import { CheckCircleIcon } from "#app-component-lib/icons/outline/CheckCircleIcon";
import { ErrorCircleIcon } from "#app-component-lib/icons/outline/ErrorCircleIcon";
import { WarningCircleIcon } from "#app-component-lib/icons/outline/WarningCircleIcon";

export type RenderStateSectionProps = {
  variant: "loading" | "success" | "warning" | "error";
  title?: JSX.Element;
  body?: JSX.Element;
  height?: "sm" | "md" | "lg" | "xl";
  withIcon?: boolean;
  cta?: JSX.Element;
  children?: React.ReactNode;
};

export function RenderStateSection({
  variant,
  withIcon = true,
  title,
  body,
  height,
  cta,
  children,
}: RenderStateSectionProps) {
  return (
    <>
      {children}
      <div
        className={cx("flex flex-col items-center justify-center", {
          "min-h-96": height === "xl",
          "min-h-72": height === "lg",
          "min-h-52": height === "md",
          "min-h-28": height === "sm",
        })}
      >
        {withIcon && (
          <>
            {variant === "loading" && <LoadingSpinner />}
            {variant === "success" && (
              <IconFrame Icon={CheckCircleIcon} color="positive" />
            )}
            {variant === "error" && (
              <IconFrame Icon={ErrorCircleIcon} color="negative" />
            )}
            {variant === "warning" && (
              <IconFrame Icon={WarningCircleIcon} color="warning" />
            )}
          </>
        )}
        {title || body || cta ? <div className="mb-4" /> : null}
        {title && (
          <span className="text-title-lg">
            {title}
            <div className="mb-2" />
          </span>
        )}
        {body && (
          <span className="text-center">
            {body}
            {cta && <div className="mb-16" />}
          </span>
        )}
        {cta && cta}
      </div>
    </>
  );
}
