import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  checkouts: () => "/checkouts",
  recurringBilling: () => "/recurring-billing",
  createCheckout: () => "/create-checkout",
  createSubscriptionPlan: () => "/create-subscription-plan",
  editSubscriptionPlan: (id: string) => `/edit-subscription-plan/${id}`,
  createSubscriptionPlanOffer: () => "/create-subscription-plan-offer",
  editSubscriptionPlanOffer: (id: string) =>
    `/edit-subscription-plan-offer/${id}`,
  createSubscription: () => "/create-subscription",
  getCheckout: (id: string) => `/checkout/attempt/${id}`,
  getSubscription: (id: string) => `/checkout/subscription/${id}`,
  settings: () => "/settings",
};

export class MerchantRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return (
      super.isRestrictedRoute(pathname) ||
      [
        RouteMap.createCheckout(),
        RouteMap.checkouts(),
        RouteMap.recurringBilling(),
        RouteMap.createSubscriptionPlan(),
        RouteMap.editSubscriptionPlan("[id]"),
        RouteMap.createSubscriptionPlanOffer(),
        RouteMap.editSubscriptionPlanOffer("[id]"),
        RouteMap.createSubscription(),
        RouteMap.getCheckout("[id]"),
        RouteMap.getSubscription("[id]"),
        RouteMap.settings(),
      ].includes(pathname || this.pathname)
    );
  }

  /*
   * Restricted Routes - goTo
   */

  goToCreateCheckout() {
    this.goTo(RouteMap.createCheckout());
  }

  goToCheckout(id: string) {
    this.goTo(RouteMap.getCheckout(id));
  }

  goToSubscription(id: string) {
    this.goTo(RouteMap.getSubscription(id));
  }

  goToCheckouts() {
    this.goTo(RouteMap.checkouts());
  }

  goToRecurringBilling() {
    this.goTo(RouteMap.recurringBilling());
  }

  gotToCreateSubscriptionPlan() {
    this.goTo(RouteMap.createSubscriptionPlan());
  }

  gotToCreateSubscriptionPlanOffer() {
    this.goTo(RouteMap.createSubscriptionPlanOffer());
  }

  gotToCreateSubscription() {
    this.goTo(RouteMap.createSubscription());
  }

  goToSettings() {
    this.goTo(RouteMap.settings());
  }

  goToEditSubscriptionPlan(id: string) {
    this.goTo(RouteMap.editSubscriptionPlan(id));
  }

  goToEditSubscriptionPlanOffer(id: string) {
    this.goTo(RouteMap.editSubscriptionPlanOffer(id));
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteCheckouts() {
    return (
      this.pathname === RouteMap.createCheckout() ||
      this.pathname === RouteMap.checkouts()
    );
  }

  isCurrentRouteSettings() {
    return this.pathname === RouteMap.settings();
  }

  isCurrentRouteRecurringBilling() {
    return (
      this.pathname === RouteMap.createSubscriptionPlan() ||
      this.pathname === RouteMap.createSubscription() ||
      this.pathname === RouteMap.recurringBilling()
    );
  }
}
