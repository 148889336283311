import {
  ButtonContent,
  ButtonPropsBase,
  buildButtonClassNames,
} from "#app-component-lib/misc/Button/ButtonCore";
import {
  TooltipWithoutWrapper,
  generateTtId,
} from "#app-component-lib/overlay/Tooltip/Tooltip";

export type ButtonProps = ButtonPropsBase &
  (
    | {
        htmlType?: "button";
        onClick: (e: React.MouseEvent<HTMLElement>) => void;
      }
    | {
        htmlType?: "submit";
        onClick?: never;
      }
  );

export function Button({
  text,
  type,
  status = "default",
  loading,
  disabled,
  tooltip,
  block,
  iconPosition,
  iconOnly,
  Icon,
  htmlType = "button",
  onClick,
}: ButtonProps) {
  const ttId = tooltip ? `button-tt-${generateTtId()}` : undefined;

  return (
    <>
      <button
        data-tooltip-id={ttId}
        aria-label={iconOnly ? text : undefined}
        type={htmlType}
        className={buildButtonClassNames({
          type,
          loading,
          disabled,
          status,
          block,
        })}
        disabled={disabled || loading}
        onClick={htmlType === "button" ? onClick : undefined}
      >
        <ButtonContent
          type={type}
          text={text}
          loading={loading}
          disabled={disabled}
          iconPosition={iconPosition}
          iconOnly={iconOnly}
          Icon={Icon}
        />
      </button>
      {tooltip && (
        <TooltipWithoutWrapper tooltipId={ttId as string}>
          {tooltip}
        </TooltipWithoutWrapper>
      )}
    </>
  );
}
