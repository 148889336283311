import { ReactNode } from "react";
import { MainLoadingGate } from "#app-common/components/MainLoadingGate";
import { useIntlProvider } from "#app-common/hooks/useIntlProvider";

export type I18nLoadingGateProps = {
  children: ReactNode;
};
export function I18nLoadingGate({ children }: I18nLoadingGateProps) {
  const { hasCatalogLoaded } = useIntlProvider();

  if (!hasCatalogLoaded) {
    return <MainLoadingGate />;
  }

  return children;
}
