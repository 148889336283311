import cx from "classnames";

export type MainProps = {
  children: React.ReactNode;
  className?: string;
  maxWidthScreen?: "sm" | "md" | "lg" | "xl" | "none";
  backgroundClassName?: string;
  paddingStrategy?: "full" | "compact";
};

export function Main({
  children,
  className,
  maxWidthScreen = "xl",
  backgroundClassName = "bg-bg-main",
  paddingStrategy = "full",
}: MainProps) {
  return (
    <main
      data-testid="main"
      className={cx(
        "flex w-full justify-center",
        className,
        backgroundClassName
      )}
    >
      <div
        className={cx("w-full px-0.5 py-8 lg:px-14", {
          "max-w-screen-sm": maxWidthScreen === "sm",
          "max-w-screen-md": maxWidthScreen === "md",
          "max-w-screen-lg": maxWidthScreen === "lg",
          "max-w-screen-xl": maxWidthScreen === "xl",
          "xs:px-4 sm:px-8": paddingStrategy === "full",
          "xs:px-2 sm:px-4": paddingStrategy === "compact",
        })}
      >
        {children}
      </div>
    </main>
  );
}
