import { getActiveAccount, logError, useEnvStore } from "@pl/app-services";

const RouteMap = {
  /*
   * Unrestricted Account Routes
   */

  login: () => "/login",
  logout: () => "/logout",
  forgotPassword: () => "/forgot",
  resetPassword: () => "/reset-password",
  signUp: () => "/sign-up",

  /*
   * Unrestricted Error Routes
   */

  sessionExpired: () => "/session-expired",
  invalidSession: () => "/invalid-session",
  fourOhFour: () => "/404",

  /*
   * Restricted Routes
   */

  index: () => "/",
};

export abstract class BaseRouterModel {
  protected readonly basePath: string;
  protected readonly pathname: string;
  protected readonly nextPush: (pathname: string) => void;

  constructor(
    basePath: string,
    pathname: string,
    nextPush: (pathname: string) => void
  ) {
    this.basePath = basePath;
    this.pathname = pathname;
    this.nextPush = nextPush;
  }

  getRouteMap() {
    return RouteMap;
  }

  /**
   *
   * Types:
   * - internal: Next.js router push
   * - external: window.location.assign;
   *   can be used for external links and switching apps
   *
   * @param url
   * @param type
   */
  goTo(url: string, type: "internal" | "external" = "internal") {
    if (type === "internal") {
      this.nextPush(url);
    } else if (type === "external") {
      // https://stackoverflow.com/a/50604116
      window.location.assign(url);
    }
  }

  /*
   * Route Group Reflection
   */

  isUnrestrictedAccountRoute(pathname?: string) {
    return [
      RouteMap.login(),
      RouteMap.logout(),
      RouteMap.forgotPassword(),
      RouteMap.resetPassword(),
      RouteMap.signUp(),
    ].includes(pathname || this.pathname);
  }

  isUnrestrictedErrorRoute(pathname?: string) {
    return [
      RouteMap.sessionExpired(),
      RouteMap.fourOhFour(),
      RouteMap.invalidSession(),
    ].includes(pathname || this.pathname);
  }

  isRestrictedRoute(pathname?: string) {
    return [RouteMap.index()].includes(pathname || this.pathname);
  }

  /*
   * Unrestricted Account Routes - goTo
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToLogin(...args: string[]) {
    this.goTo(RouteMap.login(), "external");
  }

  goToLogout() {
    this.goTo(RouteMap.logout(), "external");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToForgotPassword(...args: string[]) {
    this.goTo(RouteMap.forgotPassword(), "external");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToResetPassword(...args: string[]) {
    this.goTo(RouteMap.resetPassword(), "external");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToSignUp(...args: string[]) {
    this.goTo(RouteMap.signUp(), "external");
  }

  /*
   * Unrestricted Account Routes - Reflection
   */

  isCurrentRouteLogin() {
    return this.pathname === RouteMap.login();
  }

  /*
   * Unrestricted Error Routes - goTo
   */

  goToSessionExpired() {
    this.goTo(RouteMap.sessionExpired());
  }

  goToInvalidSession() {
    this.goTo(RouteMap.invalidSession());
  }

  goToFourOhFour() {
    this.goTo(RouteMap.fourOhFour());
  }

  /*
   * Restricted Routes - goTo
   */

  goToIndex() {
    const accountType = getActiveAccount()?.type;
    const { app } = useEnvStore.getState();

    // This shouldn't happen - should only be possible to get to the index if
    // auth is successful, in which case there should be an active account. It
    // could happen in development though, if you're blowing away the account
    // from local storage but keeping the auth token around, in which case the
    // app will try to go to the index but not have an account to go to.
    if (!accountType) {
      logError({
        message: "No active account found in BaseRouterModel goToIndex",
      });
      this.goToLogin();
      // If the account type is not the same as the app type, we need to cross
      // zones. The main cases for this is the redirect after gateway auth, and
      // cross-zone account selection in the top nav.
    } else if (app !== accountType) {
      this.goTo(`/${accountType}${RouteMap.index()}`, "external");
    } else {
      // Otherwise we're inside the target app and can just go straight to the
      // index.
      this.goTo(RouteMap.index());
    }
  }

  goToSettings() {
    logError({
      message: "Not implemented: goToSettings called on GatewayRouterModel",
    });
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteIndex() {
    return this.pathname === RouteMap.index();
  }

  isCurrentRouteSettings() {
    logError({
      message:
        "Not implemented: isCurrentRouteSettings called on BaseRouterModel",
    });

    return false;
  }
}
