import { IconProps } from "../types";
import { getIconClassNames, getIconA11yAttrs, getIconTitle } from "../utils";

/*
 * Material Icon: Cancel
 * https://fonts.google.com/icons?selected=Material%20Symbols%20Rounded%3Acancel%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4020
 */
export function ErrorCircleIcon(props: IconProps) {
  return (
    <svg
      className={getIconClassNames(props)}
      {...getIconA11yAttrs(props)}
      viewBox="0 -960 960 960"
    >
      {getIconTitle(props)}
      <path d="m480-429 116 116q11 11 25.5 10.5T647-314q11-11 11-25.5t-11-25.457L531-480.5l116-115.543Q658-607 658-621.5T647-647q-11-11-25.5-11T596-647L480-531 364-647q-11-11-25-11t-25 11q-11 11-11 25.5t10.913 25.5L429-480 313-364q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.413-10.913L480-429Zm.276 333Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z" />
    </svg>
  );
}
