import { useEffect, useRef, useState } from "react";
import {
  PL_APP_MOUNTED_EVENT_ID,
  PL_AUTH_PAYLOAD_EVENT_ID,
} from "#app-services/utils/iframe";

async function loadMsw(basePath?: string) {
  // The explicit window check shouldn't be necessary since the effect is
  // only ran client side..? But without this check we see a console error:
  // `Error: Module not found: Package path ./browser is not exported from
  // package ...` This appears to be a Next issue, trying to pull in the
  // dependency in Node where it's incompatible
  // https://github.com/mswjs/msw/issues/1877 (specifically
  // https://github.com/mswjs/msw/issues/1877#issuecomment-1904586934). Maybe
  // it's that Next automatically handles the export conditions differently when
  // gated by a check like this, which it determines to be client-side only?
  // Either way doesn't appear to be a relatively simple better solution, so
  // this works.
  if (typeof window !== "undefined") {
    if (
      process.env.NODE_ENV !== "production" &&
      process.env.NEXT_PUBLIC_ENABLE_MSW === "true"
    ) {
      return import("@pl/utl-testing/api/msw/worker").then((module) => {
        return module.worker.start({
          ...module.config,
          ...(basePath && {
            serviceWorker: {
              url: `${basePath || ""}/mockServiceWorker.js`,
              options: {
                scope: "/",
              },
            },
          }),
        });
      });
    }
  }
}

export function useMsw(basePath?: string) {
  const [isLoading, setIsLoading] = useState(true);
  const hasInitializedMsw = useRef(false);

  useEffect(() => {
    if (hasInitializedMsw.current) {
      return;
    }

    // Suppresses an MSW warning `Found a redundant "worker.start()" call.
    // Note that starting the worker while mocking is already enabled will
    // have no effect. Consider removing this "worker.start()" call.`
    // Not clear why this happens, assuming it's due to React dev mode
    // triggering the effect twice.
    hasInitializedMsw.current = true;

    loadMsw(basePath).then(() => {
      setIsLoading(false);
    });
  }, [basePath]);

  return { isLoading };
}

export type UseDevUtilsArgs = {
  basePath?: string;
  embeddable?: boolean;
};

export function useDevUtils({ basePath, embeddable }: UseDevUtilsArgs) {
  const { isLoading } = useMsw(basePath);

  useEffect(() => {
    if (
      process.env.NODE_ENV !== "production" &&
      process.env.NEXT_PUBLIC_ENABLE_MSW === "true"
    ) {
      if (embeddable) {
        window.addEventListener("message", (event) => {
          if (event.data.id === PL_APP_MOUNTED_EVENT_ID) {
            setTimeout(() => {
              window.postMessage(
                {
                  id: PL_AUTH_PAYLOAD_EVENT_ID,
                  token: "",
                },
                "*"
              );
            }, 500);
          }
        });
      }
    }
  }, [embeddable]);

  return { isLoading };
}
